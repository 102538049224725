const correctCountTitle = (count, oneTitle, upToFourTitle, moreTitle) => {
  let modulo100 = count % 100;
  let modulo10 = count % 10;

  if (modulo100 > 4 && modulo100 < 21) {
    return moreTitle;
  }

  if (modulo10 === 1) {
    return oneTitle;
  }

  if (modulo10 > 1 && modulo10 < 5) {
    return upToFourTitle;
  }

  return moreTitle;
};

export default correctCountTitle;
